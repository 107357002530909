@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Nunito Sans Regular), local(NunitoSans-Regular), url("nunito-sans-v5-latin-regular.0898274b.woff2") format("woff2"), url("nunito-sans-v5-latin-regular.9857c151.woff") format("woff");
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  src: local(Nunito Sans SemiBold), local(NunitoSans-SemiBold), url("nunito-sans-v5-latin-600.cfe8dae4.woff2") format("woff2"), url("nunito-sans-v5-latin-600.16ba6560.woff") format("woff");
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  src: local(Nunito Sans ExtraBold), local(NunitoSans-ExtraBold), url("nunito-sans-v5-latin-800.552f9ed9.woff2") format("woff2"), url("nunito-sans-v5-latin-800.c8ca7cbe.woff") format("woff");
}

:root {
  --background: #f0f4f9;
  --primary-color: #1877bd;
  --primarylight-color: #d9e9f5;
  --secondary-color: #f6f6f6;
  --highlight-color: #fff697;
  --hover-color: #a8f7fd;
  --visual-color: #a8f7fd;
  --warning-color: #f66;
  --warningdark-color: #f55;
  --primary-dark-color: #0867ad;
  --selected-color: #98f7fd;
  --dark-color: #555;
  --gray: #888;
  --gray-color: #aaa;
  --darkgray-color: #999;
  --disabled-color: #f0f0f0;
  --light-color: white;
  --sati-color: #1877bd;
  --iretia-color: #ec8d56;
  --cepoc-color: #69528e;
  --probio-color: teal;
}

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  color: var(--dark-color);
  background: var(--background);
  margin: 0;
  padding: 0;
  font-family: Nunito Sans, sans-serif;
  font-size: 17px;
}

h1, h2, h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 800;
  font-family: Nunito Sans, sans-serif !important;
}

a {
  text-decoration: none;
}

.hide {
  display: none !important;
}

.lng {
  display: none;
}

.lng_en {
  display: inherit;
}

.highlight_background {
  background: var(--highlight-color);
}

.link_default {
  color: var(--primary-color);
  text-underline-offset: 4px;
  width: fit-content;
  padding: 8px 10px 8px 0;
  text-decoration: underline;
  transition: all .25s;
  display: block;
}

.link_default:hover {
  text-underline-offset: 2px;
}

.btn_default {
  background: var(--primary-color);
  color: var(--light-color);
  text-align: center;
  border-radius: 5px;
  width: fit-content;
  padding: 8px 15px;
  transition: all .25s;
  display: block;
}

.btn_default:hover {
  background: var(--primary-dark-color);
}

.btn_secondary {
  background: var(--gray-color);
}

.btn_outline {
  border: 1px solid var(--gray-color);
  background: none;
}

.btn_secondary:hover {
  background: var(--darkgray-color);
}

.btn_action {
  background: var(--primarylight-color);
  color: var(--dark-color);
}

.btn_action:hover {
  background: var(--disabled-color);
}

.btn_warning {
  background: var(--warning-color);
}

.btn_warning:hover {
  background: var(--warningdark-color);
}

.btn_small {
  padding: 6px 10px;
  font-size: .9rem;
}

.btn_default.disabled {
  background: var(--disabled-color);
}

.loader {
  aspect-ratio: 1;
  border: .2rem solid #fff;
  border-radius: 50%;
  height: 1.2rem;
  margin: 0 10px;
  animation: .8s linear infinite alternate l20-1, 1.6s linear infinite l20-2;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1)rotate(0);
  }

  49.99% {
    transform: scaleY(1)rotate(135deg);
  }

  50% {
    transform: scaleY(-1)rotate(0);
  }

  100% {
    transform: scaleY(-1)rotate(-135deg);
  }
}

.btn_default .loader {
  display: none;
}

.btn_default.loading .loader {
  display: block;
}

.btn_loader {
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.row {
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
  display: flex;
}

.row.small_gap {
  gap: 10px;
}

.baseline {
  align-items: end;
}

.col {
  flex: 1;
}

.col_2 {
  flex: 2;
}

.col_3 {
  flex: 3;
}

.col_4 {
  flex: 4;
}

.col_flex {
  justify-content: end;
  align-items: end;
  display: flex;
}

label {
  color: var(--gray-color);
  margin-bottom: 3px;
  font-size: 1rem;
  display: block;
}

label.label_inside {
  z-index: 2;
  color: var(--dark-color);
  padding: 5px 7px;
  position: absolute;
  top: 0;
  left: 0;
}

input, select, textarea {
  border: 1px solid var(--gray-color);
  outline: none;
  width: 100%;
  height: 32px;
  padding: 5px;
  font-family: Nunito Sans, sans-serif;
  font-size: 1rem;
}

input:focus, select:focus, textarea:focus {
  border-color: var(--primary-color);
}

input:disabled, select:disabled, textarea:disabled {
  background: var(--background);
}

textarea {
  resize: none;
  height: auto;
  overflow: hidden;
}

.input {
  position: relative;
}

.input svg {
  fill: gray;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 5px;
}

.input input {
  padding-left: 30px !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.table {
  border: 1px solid var(--gray-color);
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}

.table.with_filter {
  margin-top: 30px;
}

.tr {
  display: table-row;
  position: relative;
}

thead tr {
  background: var(--gray-color);
  color: #fff;
}

.td, td, .th, th {
  padding: 5px 10px;
  display: table-cell;
}

.td, td {
  background: var(--light-color);
  border-bottom: 1px solid var(--gray-color);
}

.th, th {
  border-right: 1px solid var(--light-color);
  font-weight: 400;
}

.th:last-child, th:last-child {
  border-right: none;
}

.td.truncate {
  width: 0;
}

.td.truncate .data {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 175px;
  overflow: hidden;
}

.select_editable {
  position: relative;
}

.select_editable select {
  width: 100%;
}

.select_editable input {
  border-right: none;
  outline: none;
  width: calc(100% - 20px);
  position: absolute;
  top: 0;
  left: 0;
}

.nav_menu_popup {
  apadding: 30px 40px;
  z-index: 100;
  background: #fff;
  border: 1px solid gray;
  border-top: none;
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
}

#nav_menu_btn {
  align-items: center;
  padding: 0 30px;
  display: flex;
}

.nav_menu_popup a {
  color: #000;
  border-bottom: 1px dotted #1877bd;
  padding: 20px 30px;
  font-size: 16px;
  line-height: 1;
  display: block;
}

.nav_menu_popup a:last-child {
  border-bottom: none;
}

.nav_menu_popup a:hover {
  text-decoration: none;
}

.nav_language:first-child {
  margin-left: 140px;
}

.nav_language {
  padding: 0 5px !important;
}

.nav_language.disabled {
  color: #ccc !important;
}
/*# sourceMappingURL=path0.daa8874d.css.map */
