@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
       url('../fonts/nunito-sans-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/nunito-sans-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
       url('../fonts/nunito-sans-v5-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/nunito-sans-v5-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
       url('../fonts/nunito-sans-v5-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/nunito-sans-v5-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
	--background:#f0f4f9;

	--primary-color:#1877bd;
	--primarylight-color:#d9e9f5;
	--secondary-color:#f6f6f6;

	--highlight-color:#FFf697;
	--hover-color:#a8f7fd;;

	--visual-color:#a8f7fd; /*44ff44;*/

	--warning-color:#ff6666;
	--warningdark-color:#ff5555;

	--primary-dark-color:#0867ad;
	--selected-color:#98f7fd;

	--dark-color:#555;
	--gray: #888;
	--gray-color:#aaa;
	--darkgray-color:#999;
	--disabled-color:#f0f0f0;
	--light-color:white;

	--sati-color:#1877bd;
	--iretia-color:#ec8d56;
	--cepoc-color:#69528e;
	--probio-color:teal;
}

*
{
	box-sizing: border-box;
}

body {
    margin:0;
    padding:0;
    height:100%; 
    font-family: "Nunito Sans",sans-serif;
	font-size:17px;
	color:var(--dark-color);
	background:var(--background);
}

h1,h2,h3{
	font-family: "Nunito Sans", sans-serif !important;
	font-weight:800;
	text-transform: uppercase;
	margin:0;
}

a
{
	text-decoration: none;
}
/* --- General --- */
.hide
{
	display:none !important;
}

.lng
{
	display:none;
}
.lng_en
{
	display:inherit;
}
.highlight_background
{
	background:var(--highlight-color);
}
.link_default 
{
	color:var(--primary-color);
	padding:8px 10px 8px 0;
	text-decoration:underline;
	display:block;
	width:fit-content;
	transition:all 0.25s;
	text-underline-offset: 4px;
}
.link_default:hover 
{
	text-underline-offset: 2px;
}
.btn_default 
{
	background:var(--primary-color);
	color:var(--light-color);
	padding:8px 15px;
	text-align: center;
	display:block;
	width:fit-content;
	transition:all 0.25s;
	border-radius:5px;
}
.btn_default:hover 
{
	background:var(--primary-dark-color);
}
.btn_secondary 
{
	background:var(--gray-color);
}
.btn_outline
{
	background:transparent;
	border:1px solid var(--gray-color);
}
.btn_outline:hover
{	
	
}
.btn_secondary:hover 
{
	background:var(--darkgray-color);
}
.btn_action 
{
	background:var(--primarylight-color);
	color:var(--dark-color);
}
.btn_action:hover 
{
	background:var(--disabled-color);
}
.btn_warning 
{
	background:var(--warning-color);
}
.btn_warning:hover 
{
	background:var(--warningdark-color);
}
.btn_small 
{
	font-size:0.9rem;
	padding:6px 10px;
}
.btn_default.disabled
{
	background:var(--disabled-color);
}

/* --- spinner ---- */
.loader {
	height: 1.2rem;
    margin: 0 10px;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 0.2rem solid white;
	animation:
	  l20-1 0.8s infinite linear alternate,
	  l20-2 1.6s infinite linear;
  }
  @keyframes l20-1{
	 0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
	 12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
	 25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
	 50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
	 62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
	 75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
	 100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
  }
  @keyframes l20-2{ 
	0%    {transform:scaleY(1)  rotate(0deg)}
	49.99%{transform:scaleY(1)  rotate(135deg)}
	50%   {transform:scaleY(-1) rotate(0deg)}
	100%  {transform:scaleY(-1) rotate(-135deg)}
  }

.btn_default .loader
{
	display:none;
}
.btn_default.loading .loader 
{
	display:block;
}
.btn_loader 
{
	display: flex;
    align-items: center;
    justify-content: center;
	padding: 10px 15px;
}

.row 
{
	display:flex;
	flex-wrap:wrap;
	gap:50px;
	width:100%;
}
.row.small_gap
{
	gap:10px;
}
.baseline 
{
	align-items: end;
}
.col
{
	flex:1;
}
.col_2 
{
	flex:2;
}
.col_3 
{
	flex:3;
}
.col_4 
{
	flex:4;
}
.col_flex 
{
	display: flex;
    justify-content: end;
    align-items: end;
}
label
{
	display:block;
	color:var(--gray-color);
	font-size:1rem;
	margin-bottom:3px;
}
label.label_inside
{
	position:absolute;
	padding:5px 7px;
	top:0;
	left:0;
	z-index:2;
	color:var(--dark-color);
}



input,select,textarea
{
	font-family: "Nunito Sans",sans-serif;
	font-size:1rem;
	padding:5px;
	width:100%;
	height:32px;
	outline:none;
	border:1px solid var(--gray-color);
}
input:focus,select:focus,textarea:focus
{
	border-color: var(--primary-color);
}
input:disabled,select:disabled,textarea:disabled
{
	background:var(--background);
}
textarea 
{
	resize: none;
    height: auto;
    overflow: hidden;
}

.input 
{
	position:relative;
}
.input svg 
{
	fill:gray;
	width:16px;
	height:16px;
	position:absolute;
	left:5px;
	top:6px;
}
.input input 
{
	padding-left:30px !important;
}

.text_right 
{
	text-align:right !important;
}
.text_center 
{
	text-align:center !important;
}

.table 
{
	width:100%;
	border:1px solid var(--gray-color);	
	border-collapse: collapse;
	text-align:left;
	font-size:1rem;
	overflow:hidden;
}
.table.with_filter
{
	margin-top:30px;
}
.table tbody 
{
	
}
.tr 
{
	display:table-row;
	position:relative;
}
thead tr 
{
	background:var(--gray-color);
	color:white;
}
.td, td, .th, th
{
	display:table-cell;
	padding:5px 10px;
}

.td, td 
{
	background:var(--light-color);
	border-bottom:1px solid var(--gray-color);
}
.th,th 
{
	font-weight: 400;
	border-right:1px solid var(--light-color);
}
.th:last-child,th:last-child 
{
	border-right:none;
}

.td.truncate
{
	width:0;
}
.td.truncate .data 
{
	width:175px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap:nowrap;
}


.select_editable
{
	position:relative;

}
.select_editable select 
{
	width:100%;
}
.select_editable input 
{
	position:absolute;
	top: 0;
	left:0;
	width:calc(100% - 20px);
	border-right:none;
	outline:none;
}
/* --- Nav --- */

.nav_menu_popup
{
   display:block;
   position:absolute;
   right:0;
   top:60px;
   display:block;
   apadding:30px 40px;
   background:white;
   border:1px solid gray;
   border-top:none;
   z-index:100;
}
#nav_menu_btn
{
	padding:0 30px;
	display: flex;
    align-items: center;
}
.nav_menu_popup a
{
	display:block;
	color:black;
	padding:20px 30px;
	font-size:16px;
	line-height:1;
	border-bottom:1px dotted #1877bd;
}
.nav_menu_popup a:last-child
{
	border-bottom:none;
}
.nav_menu_popup a:hover
{
	text-decoration: none;
}

.nav_language:first-child
{
	margin-left:140px;
}

.nav_language
{
   padding:0 5px !important;
}
.nav_language.disabled
{
	color:#ccc !important;
}


